var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"view-profile"},[_c('GoBackHeader',{attrs:{"title":_vm.get_title}}),(_vm.loading)?[_c('UserProfileLoader')]:[_c('section',{staticClass:"profile-content mt-8"},[_c('div',{staticClass:"profile-content-body d-flex flex-column"},[_c('div',{staticClass:"view-profile-user-details base-card pa-0"},[_c('img',{staticClass:"view-profile-user-details-bg",attrs:{"alt":"bg","src":_vm.profile_header_bg}}),_c('div',{staticClass:"px-10 py-4 user-details d-flex flex-column flex-md-row"},[_c('div',[_c('ProfilePicture',{attrs:{"user":_vm.data.user,"own":true}}),_c('div',{staticClass:"d-flex align-center mt-7"},[_c('UserName',{attrs:{"user":_vm.data.user,"own":true}}),_c('UserRating')],1),_c('UserDesignation',{attrs:{"own":true,"user_profile":_vm.data.profile.profile ? _vm.data.profile.profile : null}}),_c('div',{staticClass:"mt-8 user-details-extra"},[_c('div',{staticClass:"details"},[_c('ExtraDetails',{attrs:{"title":_vm.$t('recruiter.view-job.sector-title'),"color":"#33BFFF","icon":_vm.industry_icon,"value":_vm.get_top_matching_job && _vm.get_top_matching_job.sector
                        ? _vm.get_top_matching_job.sector
                        : _vm.$t('shared.sector-not-found').toString()}}),(true)?_c('ExtraDetails',{attrs:{"title":_vm.$t('recruiter.view-job.track-title'),"color":"#FF8473","icon":_vm.track_icon,"value":"-"}}):_vm._e(),(
                      _vm.data.profile.profile &&
                      _vm.data.profile.profile.total_experience
                    )?_c('ExtraDetails',{attrs:{"title":_vm.$t('recruiter.view-job.experience-min-title'),"color":"#3CE17E","icon":_vm.experience_icon,"value":_vm.data.profile.profile.total_experience +
                      " " + (_vm.$t('shared.experience').toString())}}):_vm._e()],1)])],1),_c('div',{staticClass:"\n                mt-8 mt-md-0\n                ms-md-auto\n                d-flex\n                align-center\n                flex-column\n                justify-space-between\n              "},[_c('div',{staticClass:"d-flex align-center mb-2"},[_vm._l((_vm.data.user.social_links),function(social_account,index){return [_c('v-btn',{key:index,style:(!social_account.url ? 'opacity: 0.6' : ''),attrs:{"href":!social_account.url.startsWith('http')
                        ? 'https://' + social_account.url
                        : social_account.url,"disabled":!social_account.url,"target":"_blank","icon":"","large":"","color":"primary"}},[_c('p',[_vm._v(_vm._s(social_account.link))]),_c('img',{staticClass:"user-details-social-icon",attrs:{"alt":social_account.platform,"src":_vm.social_icons[social_account.platform]}})])]})],2),_c('v-btn',{staticClass:"my-4 base-submit-button user-details-btn details-btn",attrs:{"disabled":!_vm.data.user.resume_uri,"href":_vm.data.user.resume_uri,"download":""}},[_c('inline-svg',{staticClass:"mr-3",attrs:{"transformSource":_vm.transform_resume_icon,"src":_vm.resume_btn_icon}}),_vm._v(" "+_vm._s(_vm.$t("shared.resume"))+" ")],1),_c('v-dialog',{attrs:{"max-width":"700px","transition":"dialog-top-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-8 base-submit-button user-details-btn",attrs:{"disabled":_vm.data.user.id !== _vm.user_details.id}},'v-btn',attrs,false),on),[_c('inline-svg',{staticClass:"mr-3",attrs:{"transformSource":_vm.transform_edit_icon,"src":_vm.edit_btn_icon}}),_vm._v(" "+_vm._s(_vm.$t("recruiter.view-job.edit-details"))+" ")],1)]}}]),model:{value:(_vm.edit_profile),callback:function ($$v) {_vm.edit_profile=$$v},expression:"edit_profile"}},[_c('EditProfile',{key:_vm.edit_profile_key,on:{"cancel":_vm.disable_edit_profile}})],1)],1)])]),_c('HighlightsAwards',{attrs:{"allow_add":true,"own":true,"highlights":_vm.data.profile.profile && _vm.data.profile.profile.media
              ? _vm.data.profile.profile.media
              : []}}),_c('v-row',[_c('v-col',[_c('EducationDetails',{attrs:{"allow_add":true,"allow_edit":true,"education":_vm.data.profile.profile &&
                _vm.data.profile.profile.education_detailed
                  ? _vm.data.profile.profile.education_detailed
                  : null}})],1),_c('v-col',[_c('CertificationDetails',{attrs:{"allow_add":true,"allow_edit":true,"certifications":_vm.data.profile.profile &&
                _vm.data.profile.profile.certification_detailed
                  ? _vm.data.profile.profile.certification_detailed
                  : {}}})],1)],1),_c('v-row',[_c('v-col',[_c('WorkExperience',{attrs:{"allow_add":true,"allow_edit":true,"work_details":_vm.data.profile.profile &&
                _vm.data.profile.profile.work_history_detailed
                  ? _vm.data.profile.profile.work_history_detailed
                  : null}})],1),_c('v-col',[_c('Personality',{attrs:{"assessment_user_id":_vm.user_details.id,"allow_add":true,"allow_edit":true,"personality_details":_vm.data.profile.profile &&
                _vm.data.profile.profile.personality_detailed
                  ? _vm.data.profile.profile.personality_detailed
                  : null}})],1)],1),_c('ProficiencyReport',{attrs:{"assessment":_vm.data.profile.assessment,"title":_vm.$t('recruiter.candidates.candidates.popup-title')}}),_c('SkillsWithGroups',{attrs:{"removeable":true,"title":_vm.$t('shared.profile.skills-title'),"skills":_vm.data.profile.skills,"allow_add":true,"assessment":_vm.data.profile.assessment}})],1)])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }